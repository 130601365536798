require('./bootstrap');

jQuery(function ($) {
    var body = $('body');
    var className = 'dark-mode';
    var trueText = 'true';
    var falseText = 'false';
    var switchButton = $(".switch input");

    // Get the color scheme of the browser of the user
    function get_color_scheme() {
        return (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) ? trueText : falseText;
    }

    // Toggle dark mode on
    function darkModeToggle(state) {
        Cookies.set(className, state);
        body.toggleClass(className);
        // $('.switch .switch-text').text('Light');
        $('.switch .switch-text').text() == 'Dark' ? $('.switch .switch-text').text('Light') : $('.switch .switch-text').text('Dark');

    }

    // Set darkmode if user uses switch
    switchButton.on('change', function () {
        if (this.checked) {
            darkModeToggle(trueText);
        } else {
            darkModeToggle(falseText);
        }
    });

    // Set darkmode if color scheme is set to dark by user
    if (Cookies.get(className) == undefined && get_color_scheme() == trueText) {
        darkModeToggle(trueText);
        switchButton.prop('checked', trueText);
        $('.switch .switch-text').text('Light');
    }
});

// Add class when scroll from top
jQuery(function ($) {
    var documentSelector = $(document);
    var header = $('header');
    var className = 'scroll';

    documentSelector.on('scroll', function () {
        if (documentSelector.scrollTop() >= 50) {
            header.addClass(className);
        } else {
            header.removeClass(className);
        }
    });
});

// Mobile menu
jQuery(function ($) {
    var className = 'menu-active';
    var selector = $('body, header');
    var toggleText = $('.mobile-toggle span');

    // Toggle menu button
    $('.mobile-toggle').on('click', function () {
        selector.toggleClass(className);
        selector.hasClass(className) ? toggleText.text('Sluiten') : toggleText.text('Menu');
    });

    $(window).on('click', function () {
        selector.removeClass(className);
        toggleText.text('Menu');

    });

    $('.nav-container, .mobile-toggle').on('click', function (event) {
        event.stopPropagation();
    });
});
